import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { tabbarShow: true, navShow: true, keepAlive: true, title: '首页', isleftarrow: false, },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { tabbarShow: false, navShow: false, keepAlive: false, hasBg: false },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  },
  {
    path: '/passwordlogin',
    name: 'PasswordLogin',
    meta: { tabbarShow: false, navShow: false, keepAlive: false },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/PasswordLogin.vue')
  },
  {
    path: '/station',
    name: 'Station',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '分站分布', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/station/Station.vue')
  },
  {
    path: '/stationDetails',
    name: 'StationDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '分站分布', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/station/StationDetails.vue')
  },
  {
    path: '/announce',
    name: 'Announce',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '公告列表', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/announce/Announce.vue')
  },
  {
    path: '/announceDetails',
    name: 'AnnounceDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '公告详情', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/announce/AnnounceDetails.vue')
  },
  {
    path: '/scoreRank',
    name: 'ScoreRank',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '积分排名', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/scoreRank/ScoreRank.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '新闻列表', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/news/News.vue')
  },
  {
    path: '/newsDetails',
    name: 'NewsDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '新闻详细', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "home" */ '../views/news/NewsDetails.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    meta: { tabbarShow: true, navShow: true, keepAlive: true, title: '活动', isleftarrow: false, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/Activity.vue')
  },
  {
    path: '/activityDetails',
    name: 'ActivityDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '活动详细', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/activityDetails/ActivityDetails.vue')
  },
  {
    path: '/shopDetail',
    name: 'ShopDetail',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '商品详情', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopDetail.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '报名', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/signup/Signup.vue')
  },
  {
    path: '/issueActivity',
    name: 'IssueActivity',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '发布活动', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/issueActivity/IssueActivity.vue')
  },
  {
    path: '/chooseMap',
    name: 'ChooseMap',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '选择位置', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/issueActivity/ChooseMap.vue')
  },
  {
    path: '/chooseProject',
    name: 'ChooseProject',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '选择服务项目', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ChooseProject.vue')
  },
  {
    path: '/choosePeriod',
    name: 'ChoosePeriod',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '选择服务时段', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ChoosePeriod.vue')
  },
  {
    path: '/timeByDate',
    name: 'TimeByDate',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '重复时间', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/issueActivity/TimeByDate.vue')
  },
  {
    path: '/claim',
    name: 'Claim',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '认领活动', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/claim/Claim.vue')
  },
  {
    path: '/claimDetails',
    name: 'ClaimDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '活动详细', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/claim/ClaimDetails.vue')
  },
  {
    path: '/signedUp',
    name: 'SignedUp',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '已报名', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/signedUp/SignedUp.vue')
  },
  {
    path: '/signedDetails',
    name: 'SignedDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '活动详细', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/signedUp/SignedDetails.vue')
  },
  {
    path: '/onSiteCheckIn',
    name: 'OnSiteCheckIn',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '现场签到', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/signedUp/OnSiteCheckIn.vue')
  },
  {
    path: '/activityComment',
    name: 'ActivityComment',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '活动评价', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/activityComment/ActivityComment.vue')
  },
  {
    path: '/comment',
    name: 'Comment',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '评价', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/activityComment/Comment.vue')
  },
  {
    path: '/commentDetails',
    name: 'CommentDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: false, title: '活动详细', isleftarrow: true, },
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/activityComment/CommentDetails.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: { tabbarShow: true, navShow: true, keepAlive: true, title: '商城', isleftarrow: false,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/Shop.vue')
  },
  {
    path: '/shopList',
    name: 'ShopList',
    meta: { tabbarShow: true, navShow: true, keepAlive: true, title: '商城', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopList.vue')
  },
  {
    path: '/shopHot',
    name: 'ShopHot',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '兑换热榜', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopHot.vue')
  },
  {
    path: '/shopNew',
    name: 'ShopNew',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '最新商品', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopNew.vue')
  },
  {
    path: '/shopCart',
    name: 'ShopCart',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '购物车', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopCart.vue')
  },
  {
    path: '/shopPay',
    name: 'ShopPay',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '付款', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopPay.vue')
  },
  {
    path: '/shopSearch',
    name: 'ShopSearch',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '搜索', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopSearch.vue')
  },
  {
    path: '/shopPayStatus',
    name: 'ShopPayStatus',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '付款状态', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/shop/ShopPayStatus.vue')
  },
  {
    path: '/center',
    name: 'Center',
    meta: { tabbarShow: true, navShow: true, keepAlive: true, title: '个人中心', isleftarrow: false,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Center.vue')
  },
  {
    path: '/modifyHeader',
    name: 'ModifyHeader',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '修改头像', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ModifyHeader.vue')
  },
  {
    path: '/mypub',
    name: 'Mypub',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '我的发布', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Mypub.vue')
  },
  {
    path: '/mypubDetails',
    name: 'MypubDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '活动详细', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/MypubDetails.vue')
  },
  {
    path: '/receiveAddress',
    name: 'ReceiveAddress',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '收货地址', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ReceiveAddress.vue')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '余额充值', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Recharge.vue')
  },
  {
    path: '/scoreRecord',
    name: 'ScoreRecord',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '积分记录', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ScoreRecord.vue')
  },
  {
    path: '/exchangeRecord',
    name: 'ExchangeRecord',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '兑换记录', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ExchangeRecord.vue')
  },
  {
    path: '/certificate',
    name: 'Certificate',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '我的证书', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Certificate.vue')
  },
  {
    path: '/certificateDetails',
    name: 'CertificateDetails',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '证书详细', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/CertificateDetails.vue')
  },
  {
    path: '/editAddress',
    name: 'EditAddress',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '编辑地址', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/EditAddress.vue')
  },
  {
    path: '/personInfo',
    name: 'PersonInfo',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '个人资料', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/PersonInfo.vue')
  },
  {
    path: '/myJoin',
    name: 'MyJoin',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '我的参与', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/MyJoin.vue')
  },
  {
    path: '/activityList',
    name: 'ActivityList',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '活动列表', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/ActivityList.vue')
  },
  {
    path: '/wxLogin',
    name: 'WxLogin',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '微信登录', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/login/WxLogin.vue')
  },
  {
    path: '/message',
    name: 'Message',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '消息通知', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Message.vue')
  },
  {
    path: '/messageDetail',
    name: 'MessageDetail',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '消息详情', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/MessageDetails.vue')
  },
  {
    path: '/activityPay',
    name: 'ActivityPay',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '支付', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/activity/ActivityPay.vue')
  },
  {
    path: '/apply',
    name: 'Apply',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '申请成为志愿者', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Apply.vue')
  },
  {
    path: '/applyToCaptain',
    name: 'ApplyToCaptain',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '申请成为队长', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ApplyToCaptain.vue')
  },
  {
    path: '/applyToStation',
    name: 'ApplyToStation',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '入驻申请', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/ApplyToStation.vue')
  },
  {
    path: '/myCard',
    name: 'MyCard',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '我的会员卡', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/MyCard.vue')
  },
  {
    path: '/bindingPhone',
    name: 'BindingPhone',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '绑定手机', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/login/BindingPhone.vue')
  },
  {
    path: '/free/:id',
    name: 'Free',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '自由表单', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/freeform/Free.vue')
  },
  {
    path: '/submit',
    name: 'Submit',
    meta: { tabbarShow: false, navShow: true, keepAlive: true, title: '申请证书', isleftarrow: true,},
    component: () => import(/* webpackChunkName: "login" */ '../views/personcenter/Submit.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
