'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
//var target = 'http://localhost:9090/'
var target = 'https://apivol.ygsg.net/'
// 正式环境
var socketUrl = 'wss://apivol.ygsg.net/wss'
//var socketUrl = 'wss://localhost:9090/wss'
//前端页面的地址
var fontUrl = 'https://m.ygsg.net/'

//邀请好友的地址
var inviteUrl = 'https://m.ygsg.net/#/login/?userId='

module.exports = {
  domain: domain,
  target: target,
  socketUrl: socketUrl,
  fontUrl:fontUrl,
  inviteUrl:inviteUrl
}



