<template>
  <template v-if="showNav">
    <template v-if="navShow">
    <Header v-show="navShow" :title="title" :isleftarrow="isleftarrow" :hasBg="hasBg"></Header>
  </template>
  </template>


<router-view v-slot="{ Component }" >
    <keep-alive :key="$route.path">
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
    <div v-if="tabbarShow">
      <Bottomtabbar></Bottomtabbar>
    </div>
  </router-view>
</template>

<script>

import Bottomtabbar from '@/components/Bottomtabbar'
import Header from '@/components/Header.vue'
import {getBasicInfo} from "@/api/home"
export default {
  name: 'App',
  components: {
   Bottomtabbar,
   Header
  },
  data () {
    return {
      title: '',
      isleftarrow: '',
      transitionName: 'fade',
      navShow: true,
      tabbarShow: false,
      hasBg: false,
      showNav: false,
    }
  },
    mounted () {
    this.title = this.$route.meta.title
    this.isleftarrow = this.$route.meta.isleftarrow
    this.navShow = this.$route.meta.navShow
    this.tabbarShow = this.$route.meta.tabbarShow
    this.hasBg = this.$route.meta.hasBg
  },
    watch: {
    async $route(to) {
      let result = await getBasicInfo();
      let titleData  = "山东阳光志愿者平台";
      if(result.data != null){
        if(result.data.stationName != null){
          titleData = result.data.stationName;
        }
      }
      this.title = to.meta.title
      this.isleftarrow = to.meta.isleftarrow
      this.navShow = to.meta.navShow
      this.tabbarShow = to.meta.tabbarShow
      this.hasBg = this.$route.meta.hasBg
      let ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.documentTitle = to.meta.title ? titleData +"-"+ to.meta.title : ''
        document.title = this.documentTitle
        this.showNav = false
      } else {
        this.showNav = true
      }

      // web-view下的页面内
      console.log('isMiniprogram', window.__wxjs_environment === 'miniprogram')
      // window.webkit.messageHandlers.WXPay.postMessage({body: 'abc'})

      // 页面需要滚动到顶部
      if (to.matched.some(m => m.meta.scrollToTop)) {

        setTimeout(() => {
          document.body.scrollTop = 0

        }, 300)
      }
    }
  }
}
</script>

<style>

</style>
